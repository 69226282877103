@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "DM Sans", sans-serif !important;
    font-feature-settings: "kern" !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  background: #ccc;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #727070;
}

::-webkit-scrollbar {
  display: none !important;
}

input.defaultCheckbox::before {
  content: url(../src/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}
.dark .p-dropdown-header {
  background-color: #1b2559;
}

.progress {
  height: 89.6px;
  width: 44.8px;
  animation: progress-qm7mjxmd 2s ease infinite;
}

.top,
.bottom {
  background-color: #dbdcef;
  background-image: linear-gradient(#474bff, #474bff);
  background-size: 44.8px 44.8px;
  background-repeat: no-repeat;
  width: 44.8px;
  height: 44.8px;
}

.top {
  background-position: 0 0;
  animation: progress-sn28olmd 2s ease infinite;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
}

.bottom {
  background-position: 0 44.8px;
  animation: progress-jxmr0amd 2s ease infinite;
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.bottom::after {
  content: "";
  position: absolute;
  width: 2.2px;
  height: 44.8px;
  left: 21.299999999999997px;
  background-image: linear-gradient(#474bff, transparent);
}

@keyframes progress-sn28olmd {
  95%,
  100% {
    background-position: 0 44.8px;
  }
}

@keyframes progress-jxmr0amd {
  95%,
  100% {
    background-position: 0 0;
  }
}

@keyframes progress-qm7mjxmd {
  0%,
  95% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}
@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.heartbeat {
  display: inline-block; /* or block */
  animation: heartbeat 1s infinite;
}
.dark\:bg-blueSecondary {
  background: #000;

  /* Your styles for the 'dark:bg-blueSecondary' class */
}
.dark\:p-treetable-thead {
  background: #000 !important;
}

.p-datatable .p-datatable-tbody tr {
  line-height: 3; /* Adjust the value as needed */
}

.custom-row {
  line-height: 1 !important; /* Adjust the value as needed */
}

.p-datatable .p-datatable-header {
  padding: 0px !important;
  border: none;
  background: none;
}
.p-toolbar {
  background: none;
  padding: 15px 0px 3px 0px;
}

.vl {
  border-left: 3px solid #ddd;
  height: 50px;
}
.p-paginator-current {
  font-size: 12px;
}
.dark .p-datatable-wrapper {
  border-radius: 7px 7px 0px 0px;
}
.p-datatable-wrapper {
  border-radius: 7px;
}
.p-datatable .p-datatable-thead > tr > th {
  background-color: #ddd;
  border: none;
  font-size: 0.9rem;
}
.p-datatable-emptymessage {
  font-size: 12px;
}
.p-datatable .p-paginator-bottom {
  border-radius: 0px 0px 18px 18px;
  border: none;
  font-size: 12px;
  padding: 0px;
}
#dtv_table > div.p-paginator-bottom.p-paginator.p-component {
  background-color: #ccc;
  border-radius: 0px 0px 8px 8px;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  min-width: 1.4rem;
  font-size: 12px;
  height: 1.4rem;
}
.p-dropdown-label {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.p-paginator .p-dropdown {
  height: 1.4rem;
}

.list-btn {
  border-right: 2px solid #a8a2a2;
  border-radius: 6px 0px 0px 6px;
}
.grid-btn {
  border-radius: 0px 6px 6px 0px;
}
.p-dataview .p-dataview-header {
  padding: 0px;
  background: none;
  border: none;
}
.grid {
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 20px;
}
.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: none;
  border-width: 0px;
}
.p-dataview .p-dataview-content {
  background: none;
}
.p-tag {
  font-size: 0.55rem;
}
.p-paginator {
  background: none;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #fff;
}
.dark .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  color: #444;
}
.p-datatable .p-sortable-column:focus {
  box-shadow: none;
}
.top-button {
  display: none;
  position: fixed;
  bottom: 5px;
  right: 4px;
  z-index: 9999;
  border: none;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.top-button.show {
  display: block;
}

.p-dialog-title {
  font-weight: 500 !important;
  font-size: 1rem;
}

.top-button:hover {
  opacity: 1;
}
.p-input-icon-left > i:first-of-type,
.p-input-icon-left > svg:first-of-type,
.p-input-icon-left > .p-input-prefix {
  text-shadow: 1px 1px 2px #333;
}

#root
  > div
  > div
  > div.sm\:none.duration-175.linear.fixed.\!z-50.flex.min-h-full.flex-col.bg-white.pb-10.shadow-2xl.shadow-white\/5.transition-all.dark\:\!bg-navy-800.dark\:text-white.md\:\!z-50.lg\:\!z-50.xl\:\!z-0.translate-x-0
  > ul
  > a:nth-child(1)
  > div
  > li
  :focus-visible {
  outline: none;
}
.p-tag-value {
  font-size: 0.75rem;
  font-weight: 500;
}

video {
  object-fit: contain !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0rem;
}
.p-dropdown-panel .p-dropdown-items {
  text-align: center;
  padding: 0rem;
}
.p-fluid .p-inputtext:focus {
  box-shadow: none;
}
.dark .p-dialog .p-dialog-header {
  color: #fefefe;
  background: #111c44;
}
.dark .p-dialog .p-dialog-header .p-dialog-header-icon {
  color: #fefefe;
}
.dark .p-dialog .p-dialog-content {
  background: #111c44;
  color: #fefefe;
}
.dark .p-dialog .p-dialog-footer {
  background: #111c44;
  color: #fefefe;
}
.p-fluid .p-dialog-footer .p-button {
  color: #fefefe;
  border: none;
}
.dark .p-fluid .p-inputtext {
  background-color: #1b2559 !important;
  color: #fefefe;
}
.dark .p-fluid .p-dropdown {
  background: #1b2559;
}
.dark .p-dropdown-items-wrapper {
  background: #888585;
  color: #070d22;
}

.dark .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  color: #070d22;
}
.dark .p-datatable .p-datatable-thead > tr > th {
  background-color: #1b2559;
  color: #ddd;
}
.dark .p-datatable .p-paginator-bottom {
  background-color: #1b2559;
  color: #fefefe;
}

.p-column-filter-buttonbar .p-button:not(.p-button-icon-only) {
  background-color: #422afb;
  outline: none;
  border: none;
  color: #fefefe;
}

.dark .p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
  background: #111c44;
}
.dark .p-column-filter-overlay-menu .p-column-filter-buttonbar,
.dark .p-multiselect-panel .p-multiselect-header {
  background: #111c44;
}
.dark .p-multiselect-panel {
  background: #111c44;
  color: #fefefe;
}
.dark .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  color: #fefefe;
}
.dark .p-multiselect-panel .p-multiselect-items .p-multiselect-item:hover {
  color: #ddd;
}
.p-button:enabled:active,
.p-button:not(button):not(a):not(.p-disabled):active {
  background: none;
  border: none;
  box-shadow: none;
}
.dark .p-multiselect-select-all-label {
  color: #ccc;
}
.dark .p-sortable-column-icon {
  color: #ddd;
}
.p-button:enabled:focus,
.p-button:not(button):not(a):not(.p-disabled):focus {
  background: none;
  border: none;
  box-shadow: none;
}
.field {
  display: grid;
}
.p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
  /* background: none; */
  border: none;
  box-shadow: none;
  color: #070d22;
}
.dark .p-dropdown .p-dropdown-label {
  color: #fefefe;
}
.dark .p-menu.p-menu-overlay {
  background: #070d22;
  color: #ddd;
}
.dark .p-menu .p-menuitem-link .p-menuitem-icon,
.dark .p-menu .p-menuitem-link .p-menuitem-text {
  color: #ddd;
}

body
  > div.p-datepicker.p-component.p-ripple-disabled.p-connected-overlay-enter-done {
  width: 250px;
}
.p-datepicker table td > span {
  height: 1rem;
  width: 1rem;
}
.p-datepicker table {
  width: 237px;
  font-size: 0.8rem;
}
.p-datepicker table td > span {
  border-radius: 0px;
}
.p-calendar .p-inputtext {
  padding: 10px 10px;
  text-align: left;
}
.p-tabview-nav {
  justify-content: center;
}
.p-tabview .p-tabview-nav {
  background: none;
  border: none;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  color: #070d22;
  background: none;
  font-weight: 500;
}
.dark .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  color: #ccc;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border-radius: 0px;
  height: 40px;
}

#pr_id_26 > div.p-tabview-nav-container {
  height: 40px;
}

#pr_id_26 {
  height: 40px;
}

#pr_id_26 > ul {
  height: 40px;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:hover {
  background: none;
}
.p-tabview .p-tabview-panels {
  border-radius: 0px;
}
.p-highlight {
  background-color: #a3aed0 !important;
  color: #070d22;
}

.p-carousel-indicator {
  background-color: #ddd;
}

.p-tabview
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  .p-tabview-nav-link {
  background: none;
  border-color: #adb5bd;
}

.translatez-35 {
  transform: rotateZ(35deg);
}
.ribbon-wrap {
  width: 150px;
  height: 150px;
  position: absolute;
  top: -10px;
  right: -10px;
  pointer-events: none;
}
.ribbon {
  width: 230px;
  font-size: 0.918rem;
  text-align: center;
  padding: 8px 0;
  background: #ddd;
  color: #ff0800;
  position: absolute;
  transform: rotate(45deg);
  text-transform: uppercase;
  left: -17px;
  top: 29%;
  box-shadow: inset 0 0 15px 0 #c7c7c7;
}
body
  > div.p-column-filter-overlay.p-component.p-fluid.p-column-filter-overlay-menu.p-ripple-disabled.p-connected-overlay-enter-done
  > div.p-column-filter-constraints
  > div
  > div.p-column-filter-matchmode-dropdown.p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled {
  display: none;
}
body
  > div.p-column-filter-overlay.p-component.p-fluid.p-column-filter-overlay-menu.p-ripple-disabled.p-connected-overlay-enter-done
  > div.p-column-filter-buttonbar {
  display: none;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:hover {
  background: #ffffff;
}
.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  display: none;
}
.p-inputtext:enabled:focus {
  box-shadow: none;
}
.dark .p-tabview .p-tabview-panels {
  background: transparent;
}
.dark .p-paginator .p-dropdown .p-dropdown-label {
  color: #111c44;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:hover {
  background: none;
}
.dark .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: none;
  color: #333;
}
.vehicle-card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.vehicle-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-header {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.card-body {
  padding: 20px;
}

.card-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
}

.card-actions button {
  margin-right: 8px;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0rem 1rem;
}
.p-dropdown-panel .p-dropdown-items {
  text-align: left;
}
.card-actions button:last-child {
  margin-right: 0;
}
.setting:enabled:active,
.setting:not(button):not(a):not(.p-disabled):active {
  background: none;
  color: #a9abf8;
  border: 1px solid #a9abf8;
}
.setting:enabled:hover {
  border: 1px solid #a9abf8;
}

.p-dataview .p-paginator-bottom {
  border-width: 0px;
  border-radius: 0;
}
.edit:hover {
  border: 1px solid #a9abf8 !important;
}
.dark
  .p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item.p-highlight {
  background: transparent;
}

.dark-mode .radio-input {
  background: #422afb;
}
.route3,
.route4 {
  display: none;
}
@keyframes ellipsis {
  0% {
    content: ".";
  }
  33% {
    content: "..";
  }
  67% {
    content: "...";
  }
}

.custom-toast .p-toast-summary::after {
  content: ".";
  animation: ellipsis 1s infinite;
  display: inline-block;
  width: 1em;
}

@keyframes circular-waves {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.circular-wave-animation {
  animation: circular-waves 1s ease-out;
}

.dots {
  width: 40px;
  height: 9.6px;
  background: radial-gradient(circle closest-side, #6c6161 90%, #0000) 0 0/33%
    100% space;
  clip-path: inset(0 100% 0 0);
  animation: dots-e3xtdg 1.5s steps(4) infinite;
}

.custom-tooltip {
  font-size: 0.8rem;
}

@keyframes dots-e3xtdg {
  to {
    clip-path: inset(0 -34% 0 0);
  }
}

@media (max-width: 767px) {
  /* CSS styles for screens up to 767px wide (mobile devices) */
  .grid {
    display: grid;
    grid-template-columns: auto;
    column-gap: 20px;
  }
  .footer-content {
    gap: 0.7rem;
  }

  .opt1 {
    display: grid !important;
  }
  .opt2 {
    width: 100%;
  }
  .opt3 {
    display: flex;
    justify-content: normal;
  }
  .events-table {
    width: 90vw;
  }
  .s-screen {
    width: 89vw !important;
  }

  .action {
    min-width: 10rem !important;
  }
  .v-view {
    width: 95vw !important;
  }

  #root
    > div
    > div
    > div.h-full.w-full.bg-Primary.dark\:\!bg-navy-900
    > nav
    > div.relative.mt-\[3px\].flex.h-\[61px\].w-\[355px\].flex-grow.items-center.justify-around.gap-2.rounded-full.bg-white.px-2.py-2.dark\:\!bg-navy-800.dark\:shadow-none.md\:w-\[365px\].md\:flex-grow-0.md\:gap-1.xl\:w-\[165px\].xl\:gap-2
    > div:nth-child(2)
    > div.py-2.top-4.-left-\[230px\].md\:-left-\[440px\].w-max.absolute.z-10.origin-\[65\%_0\%\].md\:origin-top-right.transition-all.duration-300.ease-in-out.scale-100 {
    left: -123px;
  }

  .text-base {
    font-size: 0.8rem;
  }
  .dropdowns {
    display: block;
  }
  .field {
    width: 94%;
  }
  .searchbox {
    width: 50vw;
  }
  .custom-tooltip {
    display: none;
  }
}

.btn-play {
  background-color: #333;
  color: #f1f1f1;
  font-weight: bold;
  display: flex;
  padding: 8px 12px;
  border-radius: 8px;
  align-items: center;
  align-self: center;
}

.p-scrollpanel-grabbed {
  background-color: #1b2559;
}
.p-scrollpanel .p-scrollpanel-bar {
  background: #0b4d8e9e;
}

.p-checkbox .p-checkbox-box {
  background: #a3aed0;
}

.dash-map-pop-text p {
  color: #333 !important;
}
.p-tabview .p-tabview-panels {
  border-radius: 20px;
}

.p-multiselect-label-container {
  display: flex;
  align-items: center;
}
